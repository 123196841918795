<template>
    <b-modal :title="$t('general.delete')" @ok="deleteItems" @hide="delete_to = null" size="md" class="w-50 h-full" dialog-class="fullHeight" content-class="overflow-visible-important" body-class="overflow-visible-important" v-model="modalActive" no-close-on-backdrop>
        <template>
            <validation-observer ref="validation">
                <b-form-group class="mb-8" :label="$t('general.delete_to')">
                    <validation-provider #default="{ errors }" :name="$t('general.delete_to')" rules="required">
                        <date-picker :first-day-of-week="2" :max-date="new Date()" v-model="delete_to" :masks="masks" :model-config="modelConfig" :is-required="true">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input class="form-control"
                                       :value="inputValue"
                                       v-on="inputEvents"
                                />
                            </template>
                        </date-picker>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
            </validation-observer>
        </template>
    </b-modal>
</template>

<script>

    import {BModal, BFormGroup} from 'bootstrap-vue'
    import {DatePicker} from 'v-calendar'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'


    export default {
        components: {
            BModal,
            BFormGroup,
            DatePicker,
            ValidationObserver,
            ValidationProvider
        },
        data() {
            return {
                delete_to: null,
                modalActive: false,
                masks: {
                    //input: 'WWWW, D. MMMM YYYY'
                    input: 'DD. MM. YYYY'
                },
                modelConfig: {
                    type: 'string',
                    mask: 'iso',
                    timeAdjust: '23:59:59'
                }
            }
        },

        props: {
            tables: {
                type: Array
            }
        },

        methods: {
            open() {
                this.modalActive = true
            },

            deleteItems() {
                this.$emit('delete', this.$dayjs(this.delete_to).format('YYYY-MM-DD'))
            }
        }
    }
</script>

<style scoped>

</style>

<style>
.overflow-visible-important{
    overflow: visible!important;
}
</style>